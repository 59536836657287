@tailwind components;

/* clears the 'X' from search input in Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

@layer components {
  input {
    &:-webkit-autofill {
      @apply autofill:!shadow-[inset_0_0_0px_1000px_#262626]; /* neutral700 */
      transition:
        background-color 0s 50000s,
        color 0s 50000s,
        font-size 0s 50000s;
    }

    &:-webkit-autofill:focus {
      @apply autofill:!shadow-[inset_0_0_0px_1000px_#262626]; /* neutral700 */
      transition:
        background-color 0s 50000s,
        color 0s 50000s,
        font-size 0s 50000s;
    }

    &:-webkit-autofill:active {
      @apply autofill:!shadow-[inset_0_0_0px_1000px_#262626]; /* neutral700 */
    }

    &:-webkit-autofill:focus[aria-invalid='true'] {
      @apply autofill:!shadow-[inset_0_0_0px_1000px_#7F1D1D]; /* red900 */
    }

    &[aria-invalid='true']:not(:focus) {
      @apply autofill:!shadow-[inset_0_0_0px_1000px_#7F1D1D]; /* red900 */
    }
  }
}
