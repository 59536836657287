/* vietnamese */
@font-face {
  font-family: '__Space_Grotesk_b7afc1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/79dc71eb81eeb04b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Space_Grotesk_b7afc1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b2f220a05f61d2fd-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Space_Grotesk_b7afc1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/178a8431cc7f87ae-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Space_Grotesk_Fallback_b7afc1';src: local("Arial");ascent-override: 89.71%;descent-override: 26.62%;line-gap-override: 0.00%;size-adjust: 109.69%
}.__className_b7afc1 {font-family: '__Space_Grotesk_b7afc1', '__Space_Grotesk_Fallback_b7afc1';font-weight: 700;font-style: normal
}.__variable_b7afc1 {--font-space-grotesk: '__Space_Grotesk_b7afc1', '__Space_Grotesk_Fallback_b7afc1'
}

/* vietnamese */
@font-face {
  font-family: '__Exo_cf29ec';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/59ce67ec56f57f4b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Exo_cf29ec';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cb30f3c84ca51532-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Exo_cf29ec';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3b401319a061ecfc-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Exo_cf29ec';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/59ce67ec56f57f4b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Exo_cf29ec';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/cb30f3c84ca51532-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Exo_cf29ec';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3b401319a061ecfc-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Exo_cf29ec';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/59ce67ec56f57f4b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Exo_cf29ec';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/cb30f3c84ca51532-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Exo_cf29ec';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3b401319a061ecfc-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Exo_Fallback_cf29ec';src: local("Arial");ascent-override: 96.27%;descent-override: 31.42%;line-gap-override: 0.00%;size-adjust: 104.08%
}.__className_cf29ec {font-family: '__Exo_cf29ec', '__Exo_Fallback_cf29ec';font-style: normal
}.__variable_cf29ec {--font-exo: '__Exo_cf29ec', '__Exo_Fallback_cf29ec'
}

.container {
 width:
        100%;
}
@media (min-width: 400px) {
 .container {
  max-width:
        400px;
 }
}
@media (min-width: 437px) {
 .container {
  max-width:
        437px;
 }
}
@media (min-width: 640px) {
 .container {
  max-width:
        640px;
 }
}
@media (min-width: 768px) {
 .container {
  max-width:
        768px;
 }
}
@media (min-width: 900px) {
 .container {
  max-width:
        900px;
 }
}
@media (min-width: 1006px) {
 .container {
  max-width:
        1006px;
 }
}
@media (min-width: 1024px) {
 .container {
  max-width:
        1024px;
 }
}
@media (min-width: 1240px) {
 .container {
  max-width:
        1240px;
 }
}
@media (min-width: 1280px) {
 .container {
  max-width:
        1280px;
 }
}
@media (min-width: 1450px) {
 .container {
  max-width:
        1450px;
 }
}
@media (min-width: 1536px) {
 .container {
  max-width:
        1536px;
 }
}
@media (min-width: 2224px) {
 .container {
  max-width:
        2224px;
 }
}
  input:-webkit-autofill:-webkit-autofill {
 --tw-shadow:
        inset 0 0 0px 1000px #262626 !important;
 --tw-shadow-colored:
        inset 0 0 0px 1000px var(--tw-shadow-color) !important;
 box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
  input:-webkit-autofill:autofill {
 --tw-shadow:
        inset 0 0 0px 1000px #262626 !important;
 --tw-shadow-colored:
        inset 0 0 0px 1000px var(--tw-shadow-color) !important;
 box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
  input:-webkit-autofill { /* neutral700 */
      -webkit-transition:
        background-color 0s 50000s,
        color 0s 50000s,
        font-size 0s 50000s;
      transition:
        background-color 0s 50000s,
        color 0s 50000s,
        font-size 0s 50000s;
    }
  input:-webkit-autofill:focus:-webkit-autofill {
 --tw-shadow:
        inset 0 0 0px 1000px #262626 !important;
 --tw-shadow-colored:
        inset 0 0 0px 1000px var(--tw-shadow-color) !important;
 box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
  input:-webkit-autofill:focus:autofill {
 --tw-shadow:
        inset 0 0 0px 1000px #262626 !important;
 --tw-shadow-colored:
        inset 0 0 0px 1000px var(--tw-shadow-color) !important;
 box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
  input:-webkit-autofill:focus { /* neutral700 */
      -webkit-transition:
        background-color 0s 50000s,
        color 0s 50000s,
        font-size 0s 50000s;
      transition:
        background-color 0s 50000s,
        color 0s 50000s,
        font-size 0s 50000s;
    }
  input:-webkit-autofill:active:-webkit-autofill {
 --tw-shadow:
        inset 0 0 0px 1000px #262626 !important;
 --tw-shadow-colored:
        inset 0 0 0px 1000px var(--tw-shadow-color) !important;
 box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
  input:-webkit-autofill:active:autofill {
 --tw-shadow:
        inset 0 0 0px 1000px #262626 !important;
 --tw-shadow-colored:
        inset 0 0 0px 1000px var(--tw-shadow-color) !important;
 box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
  input:-webkit-autofill:active { /* neutral700 */
    }
  input:-webkit-autofill:focus[aria-invalid='true']:-webkit-autofill {
 --tw-shadow:
        inset 0 0 0px 1000px #7F1D1D !important;
 --tw-shadow-colored:
        inset 0 0 0px 1000px var(--tw-shadow-color) !important;
 box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
  input:-webkit-autofill:focus[aria-invalid='true']:autofill {
 --tw-shadow:
        inset 0 0 0px 1000px #7F1D1D !important;
 --tw-shadow-colored:
        inset 0 0 0px 1000px var(--tw-shadow-color) !important;
 box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
  input:-webkit-autofill:focus[aria-invalid='true'] { /* red900 */
    }
  input[aria-invalid='true']:not(:focus):-webkit-autofill {
 --tw-shadow:
        inset 0 0 0px 1000px #7F1D1D !important;
 --tw-shadow-colored:
        inset 0 0 0px 1000px var(--tw-shadow-color) !important;
 box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
  input[aria-invalid='true']:not(:focus):autofill {
 --tw-shadow:
        inset 0 0 0px 1000px #7F1D1D !important;
 --tw-shadow-colored:
        inset 0 0 0px 1000px var(--tw-shadow-color) !important;
 box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
  input[aria-invalid='true']:not(:focus) { /* red900 */
    }

/* clears the 'X' from search input in Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.calendarWrapper {
  position: relative;
}

  .calendarWrapper > .react-datepicker__navigation *::before {
    border-width: 2px 2px 0 0;
    top: 17px;
  }

  .calendarWrapper > .react-datepicker__navigation *::before {
  --tw-border-opacity: 1;
  border-color: rgb(201 201 201 / var(--tw-border-opacity));
}

  .calendarWrapper > .react-datepicker__navigation:hover *::before {
  --tw-border-opacity: 1;
  border-color: rgb(173 173 173 / var(--tw-border-opacity));
}

  .calendarWrapper > div > .react-datepicker__header,
  .calendarWrapper > .react-datepicker__month {
    background-color: transparent;
  }

  .calendarWrapper > div > .react-datepicker__header {
    border-radius: 0;
    border: none;
    padding: 0 0 8px 0;
  }

  .calendarWrapper .react-datepicker__day--in-range {
  --tw-bg-opacity: 1;
  background-color: rgb(51 255 133 / var(--tw-bg-opacity));
}

  .calendarWrapper .react-datepicker__day--in-range {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

  .calendarWrapper > div > .react-datepicker__header > .react-datepicker__day-names {
    display: flex;
  }

  .calendarWrapper > div > .react-datepicker__header > .react-datepicker__day-names {
  border-bottom-width: 1px;
}

  .calendarWrapper > div > .react-datepicker__header > .react-datepicker__day-names {
  border-top-width: 1px;
}

  .calendarWrapper > div > .react-datepicker__header > .react-datepicker__day-names {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none !important;
  }

  .calendarWrapper > div
    > .react-datepicker__header
    > .react-datepicker__day-names
    > .react-datepicker__day-name {
  --tw-text-opacity: 1;
  color: rgb(146 146 146 / var(--tw-text-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__header
    > .react-datepicker__day-names
    > .react-datepicker__day-name {
    margin: 0;
    flex: 1;
    line-height: 40px;
    width: 40px;
    max-width: 40px;
  }

  .calendarWrapper > div
    > .react-datepicker__header
    > .react-datepicker__day-names
    > .react-datepicker__day-name:not(:last-of-type) {
  border-right-width: 1px;
}

  .calendarWrapper > div
    > .react-datepicker__header
    > .react-datepicker__day-names
    > .react-datepicker__day-name:not(:last-of-type) {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

  .calendarWrapper > div > .react-datepicker__header > .react-datepicker__current-month {
    font-size: 16px;
    font-weight: 400;
    color: #b6bece;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0 8px 0;
    line-height: 28px;
  }

  .calendarWrapper > div > .react-datepicker__month {
    margin: 0;
    border: none;
  }

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week:not(:last-of-type) {
    display: flex;
  }

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week:not(:last-of-type) {
  border-bottom-width: 1px;
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week:not(:last-of-type) {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day {
    font-size: 14px;
    font-weight: 400;
  }

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day {
  --tw-text-opacity: 1;
  color: rgb(146 146 146 / var(--tw-text-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day {
    border-radius: 0;
    line-height: 40px;
    max-width: 40px;
    width: 40px;
    margin: 0;
    flex: 1;
  }

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day:focus {
    outline: none;
  }

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day:focus {
    outline: none;
  }

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day:not(:last-of-type) {
  border-right-width: 1px;
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day:not(:last-of-type) {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--selected {
  --tw-bg-opacity: 1;
  background-color: rgb(0 163 65 / var(--tw-bg-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--selected {
  --tw-text-opacity: 1;
  color: rgb(15 15 15 / var(--tw-text-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--selected:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 163 65 / var(--tw-bg-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--selected:hover {
  --tw-text-opacity: 1;
  color: rgb(15 15 15 / var(--tw-text-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--keyboard-selected {
  --tw-bg-opacity: 1;
  background-color: rgb(0 163 65 / var(--tw-bg-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--keyboard-selected {
  --tw-text-opacity: 1;
  color: rgb(15 15 15 / var(--tw-text-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--keyboard-selected:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 163 65 / var(--tw-bg-opacity));
}

  .calendarWrapper > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--keyboard-selected:hover {
  --tw-text-opacity: 1;
  color: rgb(15 15 15 / var(--tw-text-opacity));
}

.popperCustomClass {
  z-index: 4 !important;
}

.swiper {
    width: 100%;
}

    .swiper .swiper-slide .imageContainer {
            transform: scale(0.52);
            transition: transform 0.6s ease-out;
            left: 0;
            height: 100%;
        }

    .swiper .swiper-slide-active {
        opacity: 1;
        position: relative;
    }

    .swiper .swiper-slide-active .imageContainer {
            transform: scale(1);
            transition: all 0.4s linear;
            left: 0;
            padding: 0;
        }

    .swiper .swiper-slide-active .name {
            opacity: 1;
            transition: scale 0.8s linear;
        }

    .swiper .swiper-slide-next {
        opacity: 50%;
    }

    .swiper .swiper-slide-next .imageContainer {
            transform: scale(0.92);
            left: 32px;
            padding: 0;

        }

    .swiper .swiper-slide-prev {
        opacity: 50%;
    }

    .swiper .swiper-slide-prev .imageContainer {
            transform: scale(0.92);
            left: -32px;
            padding: 0;
        }

.markdown {
    line-height: 1.6;
    color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    font-family: var(--font-exo);
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
    margin-top: 2em;
    margin-bottom: 2em;
    font-family: var(--font-space-grotesk);
}

.markdown h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    padding-bottom: 0.2em;

}

.markdown h2 {
    font-size: 2em;
    margin-bottom: 0.75em;
    padding-bottom: 0.2em;
}

.markdown p {
    font-size: 1em;
    margin-bottom: 1em;
}

.markdown ul {
    list-style-type: disc;
    padding-left: 1.5em;
    margin-bottom: 1em;
}

.markdown ul li {
    margin-bottom: 0.5em;
}

.markdown a {
    color: #EAB308;
    text-decoration: none;
}

.markdown a:hover {
    color: #33FF85;
}

.markdown code {
    padding: 2px 4px;
    font-size: 0.9em;
}

.markdown pre {
    color: #f8f8f2;
    padding: 15px;
    border-radius: 5px;
    white-space: pre-wrap !important;
}

.markdown blockquote {
    border-left: 5px solid #ddd;
    padding: 10px 20px;
    margin: 20px 0;
    font-style: italic;
    color: #FFFFFF;

}
