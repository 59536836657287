.swiper {
    width: 100%;

    .swiper-slide {

        .imageContainer {
            transform: scale(0.52);
            transition: transform 0.6s ease-out;
            left: 0;
            height: 100%;
        }
    }

    .swiper-slide-active {
        opacity: 1;
        position: relative;

        .imageContainer {
            transform: scale(1);
            transition: all 0.4s linear;
            left: 0;
            padding: 0;
        }

        .name {
            opacity: 1;
            transition: scale 0.8s linear;
        }
    }
    .swiper-slide-next {
        opacity: 50%;

        .imageContainer {
            transform: scale(0.92);
            left: 32px;
            padding: 0;

        }
    }
    .swiper-slide-prev {
        opacity: 50%;

        .imageContainer {
            transform: scale(0.92);
            left: -32px;
            padding: 0;
        }
    }
}
