.markdown {
    line-height: 1.6;
    color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    font-family: var(--font-exo);
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
    margin-top: 2em;
    margin-bottom: 2em;
    font-family: var(--font-space-grotesk);
}

.markdown h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    padding-bottom: 0.2em;

}

.markdown h2 {
    font-size: 2em;
    margin-bottom: 0.75em;
    padding-bottom: 0.2em;
}

.markdown p {
    font-size: 1em;
    margin-bottom: 1em;
}

.markdown ul {
    list-style-type: disc;
    padding-left: 1.5em;
    margin-bottom: 1em;
}

.markdown ul li {
    margin-bottom: 0.5em;
}

.markdown a {
    color: #EAB308;
    text-decoration: none;
}

.markdown a:hover {
    color: #33FF85;
}

.markdown code {
    padding: 2px 4px;
    font-size: 0.9em;
}

.markdown pre {
    color: #f8f8f2;
    padding: 15px;
    border-radius: 5px;
    white-space: pre-wrap !important;
}

.markdown blockquote {
    border-left: 5px solid #ddd;
    padding: 10px 20px;
    margin: 20px 0;
    font-style: italic;
    color: #FFFFFF;

}