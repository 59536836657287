.calendarWrapper {
  position: relative;

  > .react-datepicker__navigation *::before {
    border-width: 2px 2px 0 0;
    top: 17px;
    @apply border-neutral200;
  }

  > .react-datepicker__navigation:hover *::before {
    @apply border-neutral300;
  }

  > div > .react-datepicker__header,
  > .react-datepicker__month {
    background-color: transparent;
  }

  > div > .react-datepicker__header {
    border-radius: 0;
    border: none;
    padding: 0 0 8px 0;
  }

  .react-datepicker__day--in-range {
    @apply bg-green500 !text-black;
  }

  > div > .react-datepicker__header > .react-datepicker__day-names {
    display: flex;
    @apply border-b border-t border-neutral700;
  }

  > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none !important;
  }

  > div
    > .react-datepicker__header
    > .react-datepicker__day-names
    > .react-datepicker__day-name {
    @apply text-neutral400;
    margin: 0;
    flex: 1;
    line-height: 40px;
    width: 40px;
    max-width: 40px;
  }

  > div
    > .react-datepicker__header
    > .react-datepicker__day-names
    > .react-datepicker__day-name:not(:last-of-type) {
    @apply border-r border-neutral700;
  }

  > div > .react-datepicker__header > .react-datepicker__current-month {
    font-size: 16px;
    font-weight: 400;
    color: #b6bece;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0 8px 0;
    line-height: 28px;
  }

  > div > .react-datepicker__month {
    margin: 0;
    border: none;
  }

  > div
    > .react-datepicker__month
    > .react-datepicker__week:not(:last-of-type) {
    display: flex;
    @apply border-b border-neutral700;
  }

  > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day {
    font-size: 14px;
    font-weight: 400;
    @apply text-neutral400;
    border-radius: 0;
    line-height: 40px;
    max-width: 40px;
    width: 40px;
    margin: 0;
    flex: 1;
  }

  > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day:focus {
    outline: none;
  }

  > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day:focus {
    outline: none;
  }

  > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day:not(:last-of-type) {
    @apply border-r border-neutral700;
  }

  > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--selected {
    @apply bg-green700 text-neutral900;
  }

  > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--selected:hover {
    @apply bg-green700 text-neutral900;
  }

  > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--keyboard-selected {
    @apply bg-green700 text-neutral900;
  }

  > div
    > .react-datepicker__month
    > .react-datepicker__week
    > .react-datepicker__day--keyboard-selected:hover {
    @apply bg-green700 text-neutral900;
  }
}

.popperCustomClass {
  z-index: 4 !important;
}
